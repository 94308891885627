<template>
  <!-- Banner
================================================== -->
  <div
    class="main-search-container full-height alt-search-box centered banner"
    data-background-image="images/landsapp_banner.jpg"
  >
    <div class="main-search-inner">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="main-search-input">
              <div class="main-search-input-headline actu">
                <h2 class="actu_titre">
                  {{ $t("home.searchLand") }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </h2>
              </div>

              <div class="main-search-input-item location">
                <div id="autocomplete-container">
                  <input
                    id="autocomplete-input"
                    type="text"
                    @keypress.enter="search"
                    :placeholder="$t('home.searchInput')"
                    v-model="searchVal"
                  />
                </div>
              </div>

              <button class="button" @click="search">
                {{ $t("home.search") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Content
================================================== -->

  <section
    class="fullwidth padding-top-75 padding-bottom-70"
    data-background-color="#f9f9f9"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-md-offset-2">
          <h3 class="headline centered headline-extra-spacing">
            <strong class="headline-with-separator">{{
              $t("home.services")
            }}</strong>
          </h3>
        </div>
      </div>

      <div class="row icons-container">
        <!-- Stage -->
        <div
          class="col-md-4 cursor-pointer"
          @click="$router.push({ name: 'announces' })"
        >
          <div class="icon-box-2 h-85 with-line">
            <i class="im im-icon-Mail-Search"></i>
            <h3>LandsSearch</h3>
            <p>
              {{ $t("home.landsearch") }}
            </p>
          </div>
        </div>

        <!-- Stage -->
        <div
          class="col-md-4 cursor-pointer"
          @click="$router.push({ name: 'auction' })"
        >
          <div class="icon-box-2 h-85 with-line">
            <i class="im im-icon-Coin"></i>
            <h3>LandsAuction</h3>
            <p>
              {{ $t("home.landauction") }}
            </p>
          </div>
        </div>

        <!-- Stage -->
        <div class="col-md-4" @click="$router.push({ name: 'landsAdvisor' })">
          <div class="icon-box-2 h-85">
            <i class="fa fa-tripadvisor"></i>
            <h3>LandsAdvisor</h3>
            <p>
              {{ $t("home.landadvisor") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Info Section / End -->

  <!-- Most Visited Places -->
  <section
    class="fullwidth border-top margin-top-65 padding-top-75 padding-bottom-70"
    data-background-color="#fff"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h3 class="headline centered margin-bottom-45">
            <strong class="headline-with-separator">{{
              $t("home.lastAnn")
            }}</strong>
          </h3>
        </div>
      </div>
    </div>

    <!-- Carousel / Start -->
    <div class="flex flex-wrap justify-center">
      <!-- Listing Item -->
      <div
        v-for="(post, index) in allPosts"
        :key="index"
        class="fw-carousel-item col-md-4"
      >
        <a @click="oneClicked(post.id)" class="listing-item-container compact">
          <div class="listing-item">
            <img :src="baseUrl + '/' + post.image" alt="" />

            <div class="listing-item-content">
              <h3>{{ post.title }}</h3>
              <span>{{ post.title }}</span>
            </div>
            <!--span class="like-icon"></span-->
          </div>
        </a>
      </div>
      <!-- Listing Item / End -->
    </div>
    <!-- Carousel / End

    <div class="simple-fw-slick-carousel dots-nav flex" style="display: flex !important">

		
		<div v-for="(post, index) in allPosts"
        :key="index"
        class="fw-carousel-item">
			<a href="listings-single-page.html" class="listing-item-container compact">
				<div class="listing-item">
					<img src="images/listing-item-01.jpg" alt="">

					<div class="listing-badge now-open">Now Open</div>

					<div class="listing-item-content">
						<div class="numerical-rating" data-rating="3.5"></div>
						<h3>Tom's Restaurant</h3>
						<span>964 School Street, New York</span>
					</div>
					<span class="like-icon"></span>
				</div>
			</a>
		</div>

	

	</div-->
  </section>
  <!-- Fullwidth Section / End -->

  <!-- Info Section -->

  <!-- Recent Blog Posts -->
  <section
    class="fullwidth margin-top-0 padding-top-75 padding-bottom-75"
    data-background-color="#fff"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h3 class="headline centered margin-bottom-55">
            <strong class="headline-with-separator">{{
              $t("home.blog")
            }}</strong>
          </h3>
        </div>
      </div>

      <div class="row">
        <!-- Blog Post Item -->
        <div class="col-md-4">
          <a
            @click="$router.push({ name: 'BlogDetails1' })"
            class="blog-compact-item-container"
          >
            <div class="blog-compact-item">
              <img
                src="images/beautiful-pineapple-plant-south-africa-during-daytime.jpg"
                alt=""
              />

              <div class="blog-compact-item-content">
                <ul class="blog-post-tags">
                  <li>Febuary 27, 2022</li>
                </ul>
                <h3>
                  Valoriser les terres agricoles pour mettre fin à la faim
                </h3>
                <p>
                  Il est un fait ou plutôt un paradoxe que l’on ne souligne pas
                  assez. Le continent africain possède 65 % des terres arables
                  disponibles sur la planète et, en même temps, dépense chaque
                  année plus de 50 milliards de dollars en importation de
                  denrées alimentaires
                </p>
              </div>
            </div>
          </a>
        </div>
        <!-- Blog post Item / End -->

        <!-- Blog Post Item -->
        <div class="col-md-4">
          <a
            @click="$router.push({ name: 'BlogDetails2' })"
            class="blog-compact-item-container"
          >
            <div class="blog-compact-item">
              <img src="images/medium-shot-farmer-cornfield.jpg" alt="" />
              <div class="blog-compact-item-content">
                <ul class="blog-post-tags">
                  <li>Febuary 27, 2022</li>
                </ul>
                <h3>
                  Valorisez votre terre agricole au meilleur prix grâce à notre
                  mécanisme d'enchères.
                </h3>
                <p>
                  Avec 60% des terres arables non exploitées dans le monde,
                  l’Afrique est clairement une attraction pour toute sorte
                  d'appétit d’achats et de valorisation de la terre agricole
                </p>
              </div>
            </div>
          </a>
        </div>
        <!-- Blog post Item / End -->

        <!-- Blog Post Item -->
        <div class="col-md-4">
          <a
            @click="$router.push({ name: 'BlogDetails3' })"
            class="blog-compact-item-container"
          >
            <div class="blog-compact-item">
              <img src="images/close-up-hands-holding-plants.jpg" alt="" />
              <div class="blog-compact-item-content">
                <ul class="blog-post-tags">
                  <li>Febuary 27, 2022</li>
                </ul>
                <h3>
                  Déterminez le profil cultural de votre terre grâce à
                  l'intelligence artificielle de LandsApp.
                </h3>
                <p>
                  En Afrique de l'Ouest, la main-d'œuvre dans le secteur
                  agricole est un domaine d'intérêt clé pour les économies
                  actuelles et futures
                </p>
              </div>
            </div>
          </a>
        </div>
        <!-- Blog post Item / End -->

        <!--div class="col-md-12 centered-content">
          <a
            @click="$router.push({ name: 'BlogDetails' })"
            class="button border margin-top-10"
            >Accéder à notre blog</a
          >
        </div-->
      </div>
    </div>
  </section>
  <!-- Recent Blog Posts / End -->

  <!-- Flip banner -->
  <a
    href=""
    class="flip-banner parallax bg-red-600"
    data-background="images/slider-bg-02.jpg"
    data-color="#f91942"
    data-color-opacity="0.85"
    data-img-width="2500"
    data-img-height="1666"
  >
    <div class="flip-banner-content">
      <h2 class="flip-hidden"></h2>
    </div>
  </a>
  <!-- Flip banner / End -->
</template>

<script>
// @ is an alias to /src
//import ProductCard from "@/components/productCard.vue";
import { mapActions } from "vuex";

//var position = 0;
var msg = "VOTRE TEXTE DEFILANT";
msg = "     " + msg;
//var longue = msg.length;
var fois = 70 / msg.length + 1;
let i;
for (i = 0; i <= fois; i++) msg += msg;
/*function textdefil() {
  document.form1.deftext.value = msg.substring(position, position + 70);
  position++;
  if (position == longue) position = 0;
  setTimeout("textdefil()", 100);
}
window.onload = textdefil;
*/
export default {
  name: "Home",
  components: {
    //ProductCard,
  },
  data() {
    return {
      input: "",
      allPosts: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      searchVal: "",
    };
  },
  watch: {
    input: function (val) {
      console.log(val, "la valeur change");
    },
    posts: function (val) {
      this.allPosts = val.reverse();
      console.log(val, "la valeur change");
      this.allPosts = this.allPosts.slice(0, 5);
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.getAllPost();
    //location.reload()
  },
  computed: {
    posts() {
      console.log("la valeur change");
      // const size = 4;
      return this.$store.state.posts.all;
    },
  },
  methods: {
    ...mapActions({
      whoami: "auth/whoami",
      getAllPost: "posts/getAll",
    }),
    test(e) {
      this.input = e;
      console.log("the event is heart", e);
    },
    oneClicked(id) {
      this.$router.push({ name: "postDetails", params: { id } });
    },
    search() {
      localStorage.setItem("land.search", this.searchVal);
      this.$router.push({ name: "announces" });
    },
  },
};
</script>

<style scoped>
.banner {
  background: url("../../public/images/landsapp_banner.jpg");
}
.main-search-input {
  margin-top: 70px !important;
}

@media screen and (min-width: 1240px) {
  .main-search-input {
    margin-top: 30px !important;
  }
}

@media screen and (min-width: 700px) {
  .slick-track {
    display: flex !important;
  }
  .fw-carousel-item {
    max-width: 390px !important;
  }
}

.marquee-rtl > :first-child {
  /* placement à droite du conteneur */
  white-space: nowrap; /* pas de passage à la ligne */
  animation: defilement-rtl 15s infinite linear;
}

.icon-box-2 {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  cursor: pointer !important;
  box-shadow: 2px 2px 6px 2px rgb(0 0 0 / 7%) !important;
}

.actu {
  overflow: hidden;
  width: 100%;
  display: flex;
}

@keyframes changewidth {
  from {
    width: 100px;
  }

  to {
    width: 300px;
  }
}

.actu_titre {
  display: inline-block;
  padding: 0 25px;
  font-size: 25px;
  white-space: nowrap;
  animation: scrollTxt 15s linear infinite;
}

@keyframes scrollTxt {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
</style>
