import api from "@/api";

const actions = {
  async login({ commit }, data) {
    const res = await api["auth/login"].create(data).then((response) => {
      if (response.isSuccessful) {
        localStorage.setItem("landsapp", response.data.token);
        commit("USER_INFO", response.data);
      }
      return response.data;
    });
    return res;
  },

  async addLogs({ state }, data) {
    if (state.userInfo) {
      data.nom = state.userInfo.firstname + " " + state.userInfo.lastname;
    }

    await fetch("https://api.ipify.org?format=json")
      .then((x) => x.json())
      .then(({ ip }) => {
        data.ipAddress = ip;
      });

    const res = await api["userlog"].create(data).then((response) => {
      return response.data;
    });
    return res;
  },

  async verify({ commit }, data) {
    const res = await api["auth/verification?token=" + data]
      .all()
      .then((response) => {
        if (response.isSuccessful) {
          localStorage.setItem("landsapp", response.data.token);
          commit("USER_INFO", response.data);
        }
        return response.data;
      });
    return res;
  },

  async recover({ commit }, data) {
    const res = await api["auth/recover?token=" + data.token]
      .create(data)
      .then((response) => {
        if (response.isSuccessful) {
          //localStorage.setItem("landsapp", response.data.token);
          commit("USER_INFO", response.data);
        }
        return response;
      });
    return res;
  },

  async updateProfile({ commit }, data) {
    await api["auth/update"].update(data.id, data).then((response) => {
      if (response.isSuccessful) {
        commit("USER_INFO", response.data);
        return response.data.id;
      }
    });
  },

  async newLetter({ commit }, data) {
    await api["auth/newsletters"].create(data).then((response) => {
      if (response.isSuccessful) {
        commit;
        return response.data.id;
      }
    });
  },

  async addFavorite({ commit, state }, data) {
    let user = state.userInfo;
    if (!user.preferences) {
      user.preferences = [];
    }
    user.preferences.push({ id: data });
    let array = [];
    user.preferences.forEach((element) => {
      array.push(element.id);
    });
    await api["users"].update(user.id, user).then((response) => {
      if (response.isSuccessful) {
        localStorage.setItem("lands.pref", array);
        commit("USER_INFO", response.data);
        return response.data.id;
      }
    });
  },

  async deleteFavorite({ commit, state }, data) {
    let user = state.userInfo;

    const pref = localStorage.getItem("lands.pref").split(",");
    console.log("pref", pref, data);
    user.preferences = [];

    pref.forEach((element) => {
      if (data !== parseInt(element))
        user.preferences.push({ id: parseInt(element) });
    });
    console.log(user);

    let array = [];
    user.preferences.forEach((element) => {
      array.push(element.id);
    });
    await api["users"].update(user.id, user).then((response) => {
      if (response.isSuccessful) {
        console.log("il veut le remettre en localstorage", array);
        localStorage.setItem("lands.pref", array);
        commit("USER_INFO", response.data);
        return response.data.id;
      }
    });
  },

  async rec({ commit }, data) {
    const res = await api["auth/rec"].create(data).then((response) => {
      if (response.isSuccessful) {
        commit("USER_INF", response.data);
      }
      return response.data;
    });
    return res;
  },

  async register({ commit }, data) {
    console.log("event", commit);
    return await api["auth/register"].create(data).then((response) => {
      if (response.isSuccessful) {
        localStorage.setItem("lands.newRegisterSucces", true);
        localStorage.setItem("lands.user", response.data);
        return response.data;
      }
    });
  },
  async whoami({ commit }) {
    if (localStorage.getItem("landsapp")) {
      await api["auth/whoami"].all().then(async (response) => {
        if (response.isSuccessful) {
          commit("USER_INFO", response.data);
          commit("USER_ID", response.data.id);

          await api["users/" + response.data.id].all().then((response) => {
            if (response.isSuccessful) {
              commit("USER_INFO", response.data);
              let array = [];

              response.data.preferences.forEach((element) => {
                array.push(element.id);
              });

              localStorage.setItem("lands.pref", array);
              commit("USER_PREFERENCES", array);
            }
          });
        }
      });
    }
  },
};

export default actions;
