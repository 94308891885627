import { createApp /*Component*/ } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "leaflet/dist/leaflet.css";
import i18n from "./i18n";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

createApp(App).component("v-select", vSelect);

createApp(App).use(i18n).use(store).use(router).mount("#app");

createApp(App).config.ignoredElements = [/^ion-/];

const cacheName = "v2";

self.addEventListener("activate", (event) => {
  // Remove old caches
  event.waitUntil(
    (async () => {
      const keys = await caches.keys();
      return keys.map(async (cache) => {
        if (cache !== cacheName) {
          return await caches.delete(cache);
        }
      });
    })()
  );
});
