<template>
  <router-view class="md:px-32 pt-28 xl:py-4" />
</template>

<script></script>

<style lang="scss" scoped>
.mmenu-init .mm-menu .mm-offcanvas {
  z-index: 22222222 !important;
}
.nav_bar {
  background-color: rgba(255, 255, 255, 1);
  color: #334e6f;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

nav li:hover,
nav li:active {
}

.logo {
  background-repeat: no-repeat;
  background-image: url("/img/gros-plan-d-une-goutte-d-eau-sur-un-fond-degrade-vert-couvert-de-gouttes-d-eau-la-condensation-p3cpha.jpg");
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 900;
  font-size: 40px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 10px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.barner {
  background-image: url("/img/home-search-background1.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
}

#navigation.style-1 ul li a {
  color: white !important;
  cursor: pointer;
}

img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}

#header {
  background: #013320 !important;
}
.woocommerce form .form-row .required {
  visibility: visible;
}
</style>
