import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import detail from "../views/PostDetails.vue";

import authGuard from "./guards/auth-guard";

const routes = [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Layout.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "auction",
        name: "auction",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Auctions.vue"),
      },
      {
        path: "lands-advisor",
        name: "landsAdvisor",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/LandAdvisor.vue"),
      },
      {
        path: "postDetails/:id",
        name: "postDetails",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.detailsPost.vue
        component: detail,
        props: true,
      },
      {
        path: "setting",
        name: "setting",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.detailsPost.vue
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/profileMan.vue"),
      },
      {
        path: "announces",
        name: "announces",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Announces.vue"),
      },
      {
        path: "favorites",
        name: "favorites",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Favorites.vue"),
      },
      {
        path: "about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue"),
      },
      {
        path: "verify/:token",
        name: "verify",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/verify.vue"),
        props: true,
      },

      {
        path: "recover/:token",
        name: "recover",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/recover.vue"),
        props: true,
      },

      {
        path: "/recorver/:token",
        name: "recorver",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/verify.vue"),
        props: true,
      },

      {
        path: "sign-in",
        name: "sign-in",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Sign.vue"),
      },

      {
        path: "/post",
        name: "post",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Post.vue"),
      },

      {
        path: "/posts/details/:id",
        name: "PostsDetails",
        component: () => import("../views/detailsPost.vue"),
        props: true,
      },

      {
        path: "/blog/details/1",
        name: "BlogDetails1",
        component: () => import("../views/BlogDetails.vue"),
      },

      {
        path: "/blog/details/3",
        name: "BlogDetails3",
        component: () => import("../views/BlogDetails1.vue"),
      },

      {
        path: "/blog/details/2",
        name: "BlogDetails2",
        component: () => import("../views/BlogDetails2.vue"),
      },
    ],
  },
  {
    path: "/admin",
    name: "",
    component: "",
  },
  {
    path: "/globals",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Layout.vue"),
    children: [
      {
        path: "/:catchAll(.*)",
        name: "Not Found",
        component: () => import("../views/NotFound.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(authGuard);

export default router;
