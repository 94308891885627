import axios from "axios";
import axiosRestClient from "axios-rest-client";

const api = axiosRestClient({
  baseUrl: process.env.VUE_APP_API_BASE_URL,
});

//api.axios().defaults.withCredentials = true
api.setHeader("Content-Type", "application/json");
api.axios().interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response &&
      (401 === error.response.status || 403 === error.response.status) &&
      !window.location.host.includes("sign-in")
    ) {
      window.location.href = window.location.host.includes("http")
        ? window.location.host
        : "http://" + window.location.host;
      // location.reload(url);
    }
    return Promise.reject(error);
  }
);

if (localStorage.getItem("landsapp")) {
  // FIXME: why you do this two time
  axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem(".app.kkp")}`,
  };
  api.axios().defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    "landsapp"
  )}`;
}

export default api;
