export default {
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "culture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre culture"])},
  "home": {
    "searchLand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher des terres"])},
    "searchInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que Cherchez-vous?"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos services"])},
    "landsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendez ou achetez des terres agricoles sans difficultés en postant ou en consultant des annonces sur LandsApp."])},
    "landauction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valorisez votre terre agricole au meilleur prix grâce à notre mécanisme d'enchères."])},
    "landadvisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déterminez le profil cultural de votre terre grâce à l'intelligence artificielle de LandsApp."])},
    "lastAnn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les dernières annonces"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre blog"])},
    "accessBlog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder à notre blog"])},
    "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultat(s) trouvé(s)"])},
    "nav": {
      "Announces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annonces"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
      "Auction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enchères"])},
      "LandsAdvisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LandsAdvisor"])},
      "Hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salut"])},
      "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
      "Sign-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
      "Sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
      "Sign-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
      "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annoncer"])},
      "favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favoris"])}
    },
    "sign": {
      "Sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
      "form3": {
        "recorver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrouver votre mot de passe"])},
        "mailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez reçu un mail de récupération"])}
      },
      "form2": {
        "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénoms"])},
        "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
        "passwordmust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir au moins"])},
        "downcase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une lettre en minuscule"])},
        "uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une lettre majuscule"])},
        "specialChar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un caractère spécial."])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un chiffre"])},
        "passwordRep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter le mot de passe"])},
        "passwordSame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les deux mots de passe doivent être identiques"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])}
      },
      "form1": {
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom d'utilisateur/email ou mot de passe est erronné."])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur ou email :"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe :"])},
        "passwordlost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous perdu votre mot de passe ?"])},
        "remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se souvenir de moi"])}
      }
    },
    "footer": {
      "punch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valoriser les terres agricoles en Afrique afin de mettre fin à la faim."])},
      "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens utiles"])},
      "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
      "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre équipe"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])}
    },
    "toast": {
      "checkEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier vos mails pour confirmer votre compte"])},
      "deletedAnnounce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre annonce a correctement été supprimée"])},
      "wrongId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre email/nom d'utilisateur ou votre mot de passe est erronné"])},
      "addedToFavorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'annonce a correctement été rajoutée à vos favories"])},
      "deletedToFavorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'annonce a correctement été supprimée de vos favories"])}
    }
  },
  "announce": {
    "errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups! Quelque chose ne va pas 🙂"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
    "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un Type"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
    "titleEx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ex: Terre à vendre à dangbo"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "selectDevise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une devise"])},
    "localization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation"])},
    "localtooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour prendre automatiquement vos coordonees GPS."])},
    "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superficie"])},
    "selectUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir l'unité de l'aire"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers"])},
    "firstImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image à la une"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres images"])},
    "newsLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire à notre newsletter"])},
    "newsLetterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et prénom"])}
  },
  "advisors": {
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déterminez le profil cultural de votre terre grâce à l'intelligence artificielle de LandsApp."])},
    "fillIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez les champs ci-après avec les informations de votre sol."])},
    "ni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azote (kg/ha)"])},
    "pp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phosphore (kg/ha)"])},
    "k": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potassium (kg/ha)"])},
    "mg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magnésium (kg/ha)"])},
    "pH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH"])},
    "tem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Température (en °C)"])},
    "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pluviométrie (en mm)"])},
    "hum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humidité (en %)"])},
    "geoLoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous ne connaissez pas l'ensemble des informations demandées ci-dessus, vous pouvez simplement vous géolocaliser"])},
    "commingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Service en cours de développement)"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La culture la plus adaptée pour votre sol est : "])}
  },
  "advisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landsapp's ambition is to enhance the value of agricultural land in Africa in order to put an end to hunger. To achieve its ambition, Landsapp will help the farmers to make an informed decision before starting the cultivation of crops. Our solution gives to any farmer across Africa, at the tip of his fingers crops recommendation, plant diseases recognition and treatment and sustainable soil management tools, just with the farmer’s location as input. There is a huge need for better crop recommendation systems to alleviate the food crisis in Africa."])},
  "auction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette page est actuellement en cours de conception. <br />Nous vous  prions de bien vouloir revenir plus tard. Merci"])},
  "about": {
    
  },
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])}
}