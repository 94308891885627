export default {
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "culture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommanded crop"])},
  "home": {
    "searchLand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for land"])},
    "searchInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you looking for?"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our services"])},
    "landsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell or buy farmland without difficulty by posting or viewing ads on LandsApp."])},
    "landauction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get the best price for your farmland with our auction mechanism"])},
    "landadvisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Determine the cultural profile of your land with LandsApp's artificial intelligence."])},
    "lastAnn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The latest listings"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our blog"])},
    "accessBlog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access our blog"])},
    "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result(s) found"])},
    "nav": {
      "Announces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listing"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "Auction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auction"])},
      "LandsAdvisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LandsAdvisor"])},
      "Hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi"])},
      "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account"])},
      "Sign-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
      "Sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
      "Sign-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
      "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
      "favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])}
    },
    "sign": {
      "Sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-in"])},
      "form3": {
        "recorver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrieve your password"])},
        "mailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have received a recovery email"])}
      },
      "form2": {
        "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First names"])},
        "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
        "passwordmust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must contain at least"])},
        "downcase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A lowercase letter"])},
        "uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A capital letter"])},
        "specialChar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a special character."])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A number"])},
        "passwordRep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
        "passwordSame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both passwords must be identical"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])}
      },
      "form1": {
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your username/email or password is wrong."])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username or email:"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password:"])},
        "passwordlost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you lose your password?"])},
        "remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])}
      }
    },
    "footer": {
      "punch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuing farmland in Africa to end hunger."])},
      "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useful links"])},
      "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
      "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])}
    },
    "toast": {
      "checkEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your emails to confirm your account"])},
      "deletedAnnounce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your ad has been successfully deleted"])},
      "wrongId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email/username or password is wrong"])},
      "addedToFavorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad has been successfully added to your favorites"])},
      "deletedToFavorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad has been successfully deleted from your favorites"])}
    }
  },
  "announce": {
    "errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, something is wrong 🙂 "])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Type"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "titleEx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ex: Land for sale in dangbo"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "selectDevise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une devise"])},
    "localization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation"])},
    "localtooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour prendre automatiquement vos coordonees GPS."])},
    "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superficie"])},
    "selectUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir l'unité de l'aire"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers"])},
    "firstImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image à la une"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres images"])},
    "newsLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to our newsletter"])},
    "newsLetterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name & Surname"])}
  },
  "advisors": {
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need to know what to crop, Use the power of our AI tool."])},
    "fillIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just fill in a few details about your farmland"])},
    "ni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrogen (kg/ha)"])},
    "pp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phosphorus (kg/ha)"])},
    "k": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potassium (kg/ha)"])},
    "mg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magnesium (kg/ha)"])},
    "pH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH"])},
    "tem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature (in °C)"])},
    "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rainfall (in mm)"])},
    "hum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humidity (in %)"])},
    "geoLoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don’t have details yet? Just use our geolocation tool"])},
    "commingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Work in progress)"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our AI recommends : "])}
  },
  "advisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landsapp's ambition is to enhance the value of agricultural land in Africa in order to put an end to hunger. To achieve its ambition, Landsapp will help the farmers to make an informed decision before starting the cultivation of crops. Our solution gives to any farmer across Africa, at the tip of his fingers crops recommendation, plant diseases recognition and treatment and sustainable soil management tools, just with the farmer’s location as input. There is a huge need for better crop recommendation systems to alleviate the food crisis in Africa."])},
  "auction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page in construction. <br />Check again later."])},
  "about": {
    
  },
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])}
}